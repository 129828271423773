import { render, staticRenderFns } from "./coursesIbought.vue?vue&type=template&id=2db64a44&scoped=true"
import script from "./coursesIbought.vue?vue&type=script&lang=js"
export * from "./coursesIbought.vue?vue&type=script&lang=js"
import style0 from "./coursesIbought.vue?vue&type=style&index=0&id=2db64a44&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2db64a44",
  null
  
)

export default component.exports